import { styled } from '@material-ui/core'

export const UserTemplateContainer = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  margin: '0.5rem',
  overflow: 'hidden',
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3]
}))

export const UserTemplateOverLay = styled('div')(() => ({
  position: 'absolute',
  zIndex: 1,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.3)',
  color: 'white',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
}))

export const SlideChild = styled('div')(() => ({
  position: 'absolute',
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end'
}))
