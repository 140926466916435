import React, { useContext, useEffect } from 'react'
import { useQuery } from '@apollo/react-hooks'
import Box from '@material-ui/core/Box'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import UserProjectsList from '../UserProjectsList'
import UserProjectsFilters from '../UserProjectsFilters'
import { USER_TEMPLATES } from '../../graphql/userTemplates'
import { getFavoritesQuery } from '../../graphql/templates'
import {
  SET_FAVORITES_LIST,
  SET_STATUSES
} from '../../providers/UserProjectsProvider/action-types'
import { UserProjectsContext } from '../../providers/UserProjectsProvider'
import { pageSize } from '../../lib/constants/userTemplate'
import { useToggleButtonStyles } from './UserProjectsGroup.styles'

const UserProjectsGroup = () => {
  const classes = useToggleButtonStyles()
  const {
    variablesState: {
      search,
      type,
      orderBy,
      currentStatusValues,
      currentStatusLabel,
      selectedProjects
    },
    dispatch
  } = useContext(UserProjectsContext)

  const userTemplatesReturn = useQuery(USER_TEMPLATES, {
    variables: {
      currentStatusOptions: currentStatusValues,
      search,
      orderBy,
      first: pageSize
    }
  })

  const { data, loading } = useQuery(getFavoritesQuery, {
    fetchPolicy: 'no-cache'
  })

  useEffect(() => {
    if (!loading) {
      const favoriteTemplates = data?.me?.favorites.map(
        (favorite) => favorite.id
      )
      dispatch({
        type: SET_FAVORITES_LIST,
        payload: favoriteTemplates
      })
    }
  }, [data, loading])

  const { refetch } = userTemplatesReturn

  useEffect(() => {
    refetch({
      search,
      type,
      orderBy,
      currentStatusOptions: currentStatusValues,
      first: pageSize
    })
  }, [refetch, search, type, orderBy, currentStatusValues])

  return (
    <Box display='flex' flexDirection='column'>
      <Box alignSelf='center'>
        <ToggleButtonGroup
          size='medium'
          value={currentStatusLabel}
          exclusive
          onChange={(_, payload) => dispatch({ type: SET_STATUSES, payload })}
          aria-label='projects state'
        >
          <ToggleButton
            value='drafts'
            disabled={currentStatusValues.indexOf('SUCCESSFUL')}
            classes={{
              root: classes.root,
              selected: classes.selected
            }}
          >
            Drafts
          </ToggleButton>
          <ToggleButton
            value='published'
            disabled={!currentStatusValues.indexOf('SUCCESSFUL')}
            classes={{
              root: classes.root,
              selected: classes.selected
            }}
          >
            Published
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      <UserProjectsFilters userTemplatesReturn={userTemplatesReturn} />
      <UserProjectsList
        userTemplatesReturn={userTemplatesReturn}
        dispatch={dispatch}
        state={{
          search,
          orderBy,
          currentStatusValues
        }}
        selectedProjects={selectedProjects}
      />
    </Box>
  )
}

export default UserProjectsGroup
