/* eslint-disable import/prefer-default-export */
import { makeStyles } from '@material-ui/core/styles'

export const useToggleButtonStyles = makeStyles((theme) => ({
  root: {
    '&$selected': {
      color: 'white',
      backgroundColor: theme.palette.primary.main
    },
    backgroundColor: 'white'
  },
  selected: {}
}))
