import React, { useState } from 'react'
import Box from '@material-ui/core/Box'
import { Button } from '@material-ui/core'
import Delete from '@material-ui/icons/Delete'
import { useMutation } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'
import {
  DELETE_USER_TEMPLATE,
  USER_TEMPLATES
} from '../../graphql/userTemplates'
import { CLEAR_SELECTED_ITEMS } from '../../providers/UserProjectsProvider/action-types'
import LoadingAnimation from '../LoadingAnimation'
import UserTemplate from '../UserTemplate'
import { useInfiniteScroll } from '../../hooks'
import ResponsiveMasonry from '../MasonryLayout/ResponsiveMasonry'
import Masonry from '../MasonryLayout/Masonry'
import ConfirmationDialog from '../ConfirmationDialog'
import { pageSize } from '../../lib/constants/userTemplate'

const UserProjectsList = ({
  dispatch,
  userTemplatesReturn,
  selectedProjects,
  state
}) => {
  const { items, error, loading } = useInfiniteScroll(
    userTemplatesReturn,
    30,
    'userTemplates'
  )
  const { enqueueSnackbar } = useSnackbar()
  const [isConfirmationDialogOpen, toggleConfirmationDialog] = useState(false)
  const { search, orderBy, currentStatusValues } = state
  const [deleteUserTemplate] = useMutation(DELETE_USER_TEMPLATE, {
    variables: { ids: selectedProjects },
    onCompleted: () => {
      toggleConfirmationDialog(false)
      dispatch({
        type: CLEAR_SELECTED_ITEMS
      })
    },
    refetchQueries: [
      {
        query: USER_TEMPLATES,
        variables: {
          currentStatusOptions: currentStatusValues,
          search,
          orderBy,
          first: pageSize
        }
      }
    ],
    awaitRefetchQueries: true,
    onError: (e) => {
      enqueueSnackbar(e.message, {
        variant: 'error'
      })
      toggleConfirmationDialog(false)
    }
  })

  return (
    <Box p={2}>
      <Button
        variant='outlined'
        color='primary'
        disableElevation
        disabled={!(selectedProjects?.length > 0)}
        startIcon={<Delete />}
        onClick={() => toggleConfirmationDialog(true)}
      >
        Delete
      </Button>
      {error && <p>{error.message}</p>}
      {items && (
        <ResponsiveMasonry
          columnsCountBreakPoints={{ 350: 2, 750: 3, 900: 4, 1200: 5 }}
        >
          <Masonry columnsCount={2} gutter='0px'>
            {items.map(({ node }) => (
              <UserTemplate
                key={node.id}
                data={node}
                dispatch={dispatch}
                disableActions={!(selectedProjects?.length > 0)}
                selectedProject={selectedProjects?.indexOf(node.id) > -1}
              />
            ))}
          </Masonry>
        </ResponsiveMasonry>
      )}

      {loading && <LoadingAnimation />}
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        onClose={() => toggleConfirmationDialog(!isConfirmationDialogOpen)}
        message='Are you sure you would like to delete this project and all its progress?'
        actionLabel={loading ? <LoadingAnimation /> : 'Delete'}
        disableAction={loading}
        onConfirm={deleteUserTemplate}
      />
    </Box>
  )
}

export default UserProjectsList
