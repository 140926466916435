import React from 'react'
import UserProjectsGroup from '../UserProjectsGroup'
import UserProjectsProvider from '../../providers/UserProjectsProvider'

const UserProjects = () => {
  return (
    <UserProjectsProvider>
      <UserProjectsGroup />
    </UserProjectsProvider>
  )
}

export default UserProjects
