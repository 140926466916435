import { userProjectToggle } from '../../lib/constants/userTemplate'

const templatesListVariables = {
  search: undefined,
  type: undefined,
  orderBy: 'createdAt_DESC',
  currentStatusValues: userProjectToggle.drafts.values,
  currentStatusLabel: userProjectToggle.drafts.label,
  favoritesList: []
}

export default templatesListVariables
