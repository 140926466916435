import * as actions from './action-types'
import { userProjectToggle } from '../../lib/constants/userTemplate'

const reducer = (state, { type, payload }) => {
  switch (type) {
    case actions.SET_SEARCH:
      return {
        ...state,
        search: payload
      }
    case actions.SET_ORDER_BY:
      return {
        ...state,
        orderBy: payload
      }
    case actions.SET_TYPE:
      return {
        ...state,
        type: payload
      }
    case actions.SET_STATUSES:
      return {
        ...state,
        currentStatusValues: userProjectToggle[payload].values,
        currentStatusLabel: userProjectToggle[payload].label
      }

    case actions.SELECT_ITEM: {
      let { selectedProjects } = state
      if (payload) {
        if (selectedProjects) {
          selectedProjects.push(payload)
        } else {
          selectedProjects = [payload]
        }
      } else {
        selectedProjects = []
      }

      return {
        ...state,
        selectedProjects
      }
    }

    case actions.UNSELECT_ITEM: {
      const { selectedProjects } = state
      return {
        ...state,
        selectedProjects: selectedProjects.filter((i) => i !== payload)
      }
    }

    case actions.CLEAR_SELECTED_ITEMS:
      return {
        ...state,
        selectedProjects: []
      }

    case actions.SET_FAVORITES_LIST: {
      return {
        ...state,
        favoritesList: payload
      }
    }

    default:
      return state
  }
}

export default reducer
