import gql from 'graphql-tag'

export const getUserLibrary = gql`
  query userLibrary(
    $path: String
    $orderBy: UserLibraryOrderByInput
    $isTrash: Boolean
  ) {
    userLibrary(path: $path, orderBy: $orderBy, isTrash: $isTrash) {
      metadata {
        name
        path
        previousPath
      }
      files {
        name
        extension
        size
        lastModified
        url
        path
      }
      folders {
        name
        path
        previousPath
      }
    }
  }
`

export const getSignedUrlForUpload = gql`
  mutation uploadFile($folderPath: String!, $fileName: String!) {
    uploadFile(params: { folderPath: $folderPath, fileName: $fileName }) {
      url
      fields {
        key
        value
      }
    }
  }
`

export const confirmUploadMutation = gql`
  mutation confirmUpload($size: Int!, $path: String!, $name: String!) {
    confirmUpload(params: { size: $size, path: $path, name: $name })
  }
`

export const createFolderMutation = gql`
  mutation createFolder($parentPath: String!, $name: String!) {
    createFolder(params: { parentPath: $parentPath, name: $name })
  }
`

export const renameFileMutation = gql`
  mutation renameFile(
    $folderPath: String!
    $oldName: String!
    $newName: String!
  ) {
    renameFile(
      params: { folderPath: $folderPath, oldName: $oldName, newName: $newName }
    )
  }
`

export const renameFolderMutation = gql`
  mutation renameFolder($folderPath: String!, $newName: String!) {
    renameFolder(params: { folderPath: $folderPath, newName: $newName })
  }
`

export const moveFilesMutation = gql`
  mutation moveFiles($targetPath: String!, $filePaths: [String!]!) {
    moveFiles(params: { targetPath: $targetPath, filePaths: $filePaths })
  }
`

export const moveFoldersMutation = gql`
  mutation moveFolders($targetPath: String!, $folderPaths: [String!]!) {
    moveFolders(params: { targetPath: $targetPath, folderPaths: $folderPaths })
  }
`

export const deleteFilesMutation = gql`
  mutation deleteFiles($filePaths: [String!]!) {
    deleteFiles(params: { filePaths: $filePaths })
  }
`

export const deleteFoldersMutation = gql`
  mutation deleteFolders($folderPaths: [String!]!) {
    deleteFolders(params: { folderPaths: $folderPaths })
  }
`

export const restoreFoldersMutation = gql`
  mutation restoreFolders($folderPaths: [String!]!) {
    restoreFolders(params: { folderPaths: $folderPaths })
  }
`

export const restoreFilesMutation = gql`
  mutation restoreFiles($filePaths: [String!]!) {
    restoreFiles(params: { filePaths: $filePaths })
  }
`

export const getUserLibraryStorage = gql`
  {
    userLibraryStorage
  }
`
