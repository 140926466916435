import React, { createContext, useReducer } from 'react'
import initState from './init-state'
import reducer from './reducer'

export const UserProjectsContext = createContext(null)

const UserProjectsProvider = ({ children }) => {
  const [variablesState, dispatch] = useReducer(reducer, initState)

  return (
    <UserProjectsContext.Provider value={{ variablesState, dispatch }}>
      {children}
    </UserProjectsContext.Provider>
  )
}

export default UserProjectsProvider
