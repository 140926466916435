/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import Box from '@material-ui/core/Box'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'

const LibraryBreadCrumbs = ({
  currentDirectory,
  libraryType,
  onChange,
  role
}) => {
  const { link, path, directoryData } = currentDirectory
  const breadcrumbs =
    libraryType === 'Partnership'
      ? currentDirectory && link.split('/')
      : currentDirectory && path.split('/')

  const itemClickHandler = (item, i) => {
    if (libraryType === 'Partnership') {
      const { type, path: pathData, name } = directoryData[i]
      const newDirectoryData = directoryData
      newDirectoryData.length = i + 1
      const newLink = `${directoryData.map((elem) => elem.name).join('/')}/`

      return onChange({
        path: pathData,
        name,
        link: newLink,
        directoryData: newDirectoryData,
        type: type === 'businesses' ? 'projects' : 'folders'
      })
    }
    return onChange({
      path: item,
      name: '',
      link: '',
      directoryData: [],
      type: 'folders'
    })
  }

  const homeClickHandler = () => {
    let type = 'projects'
    if (libraryType === 'userLibrary') type = 'folders'
    if (role === 'agency') type = 'businesses'
    onChange({
      path: '',
      name: '',
      link: '',
      directoryData: [],
      type
    })
  }

  return (
    <Box my={1}>
      <Breadcrumbs aria-label='breadcrumb'>
        <Link color='inherit' href='#' onClick={() => homeClickHandler()}>
          Home
        </Link>
        {breadcrumbs.map((item, i) =>
          i === breadcrumbs.length - 1 ? (
            <Typography key={item}>{item}</Typography>
          ) : (
            <Link
              color='inherit'
              href='#'
              key={item}
              onClick={() => itemClickHandler(item, i)}
            >
              {item}
            </Link>
          )
        )}
      </Breadcrumbs>
    </Box>
  )
}

LibraryBreadCrumbs.propTypes = {
  currentDirectory: PropTypes.object.isRequired,
  libraryType: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  role: PropTypes.string
}

LibraryBreadCrumbs.defaultProps = {
  libraryType: 'userLibrary',
  role: ''
}

export default LibraryBreadCrumbs
