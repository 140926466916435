import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton'
import Grid from '@material-ui/core/Grid'

import GridViewThumbnail from '../GridViewThumbnail'
import {
  OPEN_CONFIRM_DELETE,
  UNSELECT_ITEM,
  SELECT_ITEM
} from '../../providers/UserLibraryProvider/actions-types'
import { renderFileSrc, getFileType } from '../../utils/helpers'

const LibraryFilesGrid = ({
  files,
  folders,
  loading,
  selectedItems,
  trashItems,
  dispatch,
  type,
  handleDirectoryChange,
  currentDirectory
}) => {
  const renderFolders =
    folders &&
    folders.map(({ id, path, name, size, lastModified }) => (
      <GridViewThumbnail
        hideActions={currentDirectory.type === 'businesses'}
        key={path}
        name={name}
        lastModified={lastModified}
        size={size}
        path={path}
        type='folder'
        previewUrl={renderFileSrc('folder')}
        selected={
          path.includes('.trash')
            ? trashItems.find((i) => i.path === path)
            : selectedItems.find((i) => i.path === path)
        }
        onDelete={() =>
          dispatch({
            type: OPEN_CONFIRM_DELETE,
            payload: [{ id, path, size, type: 'folder' }]
          })
        }
        onDoubleClick={() => handleDirectoryChange(path, name, id, type)}
        // onOpen={() => handleDirectoryChange(path, name, id, type)}
        onSelectChange={(selected) => {
          dispatch({
            type: selected ? UNSELECT_ITEM : SELECT_ITEM,
            payload: { id, path, size, type: 'folder' }
          })
        }}
      />
    ))

  const renderFiles =
    files &&
    files.map(({ path, name, size, extension, url, lastModified }) => (
      <GridViewThumbnail
        key={path}
        name={name}
        lastModified={lastModified}
        size={size}
        path={path}
        previewUrl={renderFileSrc(
          getFileType(extension.replace(/\./g, '')),
          url
        )}
        selected={
          path.includes('.trash')
            ? trashItems.find((i) => i.path === path)
            : selectedItems.find((i) => i.path === path)
        }
        onDelete={() =>
          dispatch({
            type: OPEN_CONFIRM_DELETE,
            payload: [
              { path, size, type: getFileType(extension.replace(/\./g, '')) }
            ]
          })
        }
        onSelectChange={(selected) =>
          dispatch({
            type: selected ? UNSELECT_ITEM : SELECT_ITEM,
            payload: {
              path,
              size,
              type: getFileType(extension.replace(/\./g, ''))
            }
          })
        }
      />
    ))

  return (
    <>
      {!loading ? (
        <Grid container spacing={1}>
          {renderFolders}
          {renderFiles}
        </Grid>
      ) : (
        <Grid container spacing={1}>
          <Grid item xs={6} sm={4} md={3} xl={2}>
            <Skeleton variant='rect' height={180} />
          </Grid>
          <Grid item xs={6} sm={4} md={3} xl={2}>
            <Skeleton variant='rect' height={180} />
          </Grid>
          <Grid item xs={6} sm={4} md={3} xl={2}>
            <Skeleton variant='rect' height={180} />
          </Grid>
          <Grid item xs={6} sm={4} md={3} xl={2}>
            <Skeleton variant='rect' height={180} />
          </Grid>
        </Grid>
      )}
    </>
  )
}
export default LibraryFilesGrid
