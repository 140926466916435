import React, { useContext, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Sort from '@material-ui/icons/Sort'
import { UserProjectsContext } from '../../providers/UserProjectsProvider'
import {
  SET_SEARCH,
  SET_ORDER_BY,
  SET_TYPE
} from '../../providers/UserProjectsProvider/action-types'
import SearchBar from '../SearchBar'
import { types, orderByValues } from '../../lib/constants/userTemplate'
import { getDirection } from '../../utils/helpers'

import SortingButton from '../SortingButton'

const UserProjectsFilters = ({ userTemplatesReturn }) => {
  const {
    variablesState: { search, type, orderBy, currentStatusValues },
    dispatch
  } = useContext(UserProjectsContext)

  const { refetch } = userTemplatesReturn

  useEffect(() => {
    refetch({
      search,
      type,
      orderBy,
      currentStatusOptions: currentStatusValues
    })
  }, [refetch, search, type, orderBy, currentStatusValues])

  const handleSearchChange = (payload) =>
    dispatch({ type: SET_SEARCH, payload })

  const handleTypeChange = (e) =>
    dispatch({
      type: SET_TYPE,
      payload: e.target.value === 'any' ? undefined : e.target.value
    })

  const handleOrderByChange = (directions) => {
    const [asc, desc] = directions
    let payload = ''
    if (directions.indexOf(orderBy) !== -1) {
      if (directions.indexOf(orderBy) === 0) payload = desc
      else payload = asc
    } else {
      payload = asc
    }
    dispatch({ type: SET_ORDER_BY, payload })
  }

  return (
    <Box p={3} display='flex' justifyContent='space-between'>
      <SearchBar
        label='Search'
        minWidth='500px'
        my={3}
        initialValue={search}
        onChange={handleSearchChange}
        onSubmit={() => {}}
      />
      <Box display='flex'>
        <Box display='flex' color='inherit' alignItems='center'>
          <Typography variant='h5' component='span'>
            Show:
          </Typography>
          <Box mx={1} color='inherit'>
            <Select
              defaultValue='any'
              name='userTemplateType'
              id='userTemplateType'
              onChange={handleTypeChange}
            >
              <MenuItem value='any' selected={!type}>
                <em>Any</em>
              </MenuItem>
              {types.map(({ displayName, slug }) => (
                <MenuItem key={slug} value={slug} selected={slug === type}>
                  {displayName}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
        <Box display='flex' justifySelf='flex-end' alignItems='center'>
          <Box>
            <Sort />
          </Box>
          <Box display='flex'>
            {Object.keys(orderByValues).map((value) => {
              const direction = getDirection(orderByValues[value], orderBy)
              return (
                <SortingButton
                  key={value}
                  criteria={value}
                  direction={direction}
                  handleClick={() => handleOrderByChange(orderByValues[value])}
                />
              )
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default UserProjectsFilters
