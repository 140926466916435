const SET_SEARCH = 'SET_SEARCH'
const SET_ORDER_BY = 'SET_ORDER_BY'
const SET_TYPE = 'SET_TYPE'
const SET_STATUSES = 'SET_STATUSES'
const SELECT_ITEM = 'SELECT_ITEM'
const UNSELECT_ITEM = 'UNSELECT_ITEM'
const CLEAR_SELECTED_ITEMS = 'CLEAR_SELECTED_ITEMS'
const SET_FAVORITES_LIST = 'SET_FAVORITES_LIST'

export {
  SET_SEARCH,
  SET_ORDER_BY,
  SET_TYPE,
  SET_STATUSES,
  SELECT_ITEM,
  UNSELECT_ITEM,
  CLEAR_SELECTED_ITEMS,
  SET_FAVORITES_LIST
}
