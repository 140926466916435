import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import Skeleton from '@material-ui/lab/Skeleton'
import EditOutlined from '@material-ui/icons/EditOutlined'
import DeleteOutlined from '@material-ui/icons/DeleteOutlined'
import Grid from '@material-ui/core/Grid'
import GridListTileBar from '@material-ui/core/GridListTileBar'
import IconButton from '@material-ui/core/IconButton'
import Box from '@material-ui/core/Box'
import Fade from '@material-ui/core/Fade'
import Checkbox from '@material-ui/core/Checkbox'
import Typography from '@material-ui/core/Typography'
import {
  GridItemContent,
  useGridItemStyles,
  GridItemThumbnail,
  GridItemWrapper,
  GridItemVideo
} from './GridViewThumbnail.styles'
import { formatBytes } from '../../utils/helpers'
import { useSingleAndDoubleClick } from '../../hooks'

const GridViewThumbnail = ({
  type,
  name,
  lastModified,
  url,
  size,
  previewUrl,
  selected,
  hideActions,
  gridColumns,
  onDelete,
  onEdit,
  onOpen,
  onDoubleClick,
  onSelectChange,
  path
}) => {
  const classes = useGridItemStyles()
  const [dimensions, setDimensions] = useState(null)
  const imgRef = useRef(null)
  const videoRef = useRef(null)

  const handleLoad = () =>
    setDimensions({
      width: videoRef.current
        ? videoRef.current.videoWidth
        : imgRef.current.naturalWidth,
      height: videoRef.current
        ? videoRef.current.videoHeight
        : imgRef.current.naturalHeight
    })

  const handleVideoOver = (e) => e.target.play()
  const handleVideoLeave = (e) => {
    e.target.currentTime = 0
    e.target.pause()
  }

  // eslint-disable-next-line radix
  const date = new Date(parseInt(lastModified)).toLocaleString()
  const clickHandler = useSingleAndDoubleClick(
    dimensions,
    onOpen,
    onDoubleClick
  )

  return (
    <Grid item xs={gridColumns + 3} sm={gridColumns + 1} md={gridColumns}>
      <GridItemWrapper>
        {path === '.trash' || hideActions || (
          <Checkbox
            style={{ top: 0, left: 0, position: 'absolute', zIndex: '999' }}
            checked={selected}
            onChange={() => onSelectChange(selected)}
          />
        )}
        <GridItemContent type={type} onClick={() => clickHandler()}>
          <Fade in={dimensions}>
            {type === 'video' ? (
              <GridItemVideo
                ref={videoRef}
                src={url}
                width='100%'
                height='100%'
                muted
                onLoadedData={handleLoad}
                style={{ position: 'absolute', top: 0 }}
                onMouseEnter={handleVideoOver}
                onMouseLeave={handleVideoLeave}
              />
            ) : (
              <GridItemThumbnail
                src={previewUrl}
                onLoad={handleLoad}
                folder={type === 'folder'}
                ref={imgRef}
                // crossOrigin='anonymous'
              />
            )}
          </Fade>
          {!dimensions && (
            <Box
              position='absolute'
              width='100%'
              height='100%'
              top={0}
              left={0}
            >
              <Skeleton variant='rect' width='100%' height='100%' />
            </Box>
          )}
        </GridItemContent>
        <GridListTileBar
          title={name}
          style={{ pointerEvents: 'none' }}
          subtitle={
            <Box display='flex' flexDirection='column'>
              <Typography variant='caption'>{lastModified && date}</Typography>
              {type !== 'folder' && (
                <Typography variant='caption'>
                  {size
                    ? formatBytes(size)
                    : dimensions &&
                      `${dimensions.width}px by ${dimensions.height}px`}
                </Typography>
              )}
            </Box>
          }
          actionIcon={
            (path && path.includes('.trash')) ||
            hideActions || (
              <Box display='flex' style={{ pointerEvents: 'all' }}>
                <IconButton className={classes.icon} onClick={onEdit}>
                  <EditOutlined fontSize='small' />
                </IconButton>
                <IconButton className={classes.icon} onClick={onDelete}>
                  <DeleteOutlined fontSize='small' />
                </IconButton>
              </Box>
            )
          }
          classes={{
            root: classes.titleBar,
            title: classes.title
          }}
        />
      </GridItemWrapper>
    </Grid>
  )
}

GridViewThumbnail.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  type: PropTypes.string,
  size: PropTypes.number,
  gridColumns: PropTypes.number,
  previewUrl: PropTypes.string,
  lastModified: PropTypes.string,
  selected: PropTypes.bool,
  hideActions: PropTypes.bool,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onOpen: PropTypes.func,
  onDoubleClick: PropTypes.func,
  onSelectChange: PropTypes.func,
  path: PropTypes.string
}

GridViewThumbnail.defaultProps = {
  type: 'image',
  size: 0,
  gridColumns: 3,
  previewUrl: null,
  lastModified: null,
  selected: false,
  hideActions: false,
  path: '',
  onDelete: () => {},
  onEdit: () => {},
  onOpen: () => {},
  onDoubleClick: () => {},
  onSelectChange: () => {}
}

export default GridViewThumbnail
